import { OUTLET_CHANNEL_KEY } from 'utils/constants';

// Flatten products and add category ID to each product
const flattenProducts = (products) => {
  return Object.values(products)
    .flat()
    .map((product) => ({
      ...product,
      categoryId: Object.keys(products).find((key) =>
        products[key].some((p) => p.id === product.id),
      ),
    }));
};

const hasNonOutletPrices = (product) => {
  const result = product?.variants?.some((variant) =>
    variant?.prices?.some((price) => price?.channel?.obj?.key !== OUTLET_CHANNEL_KEY),
  );
  const masterVariantResult = product?.masterVariant?.prices?.some(
    (price) => price?.channel?.obj?.key !== OUTLET_CHANNEL_KEY,
  );
  return (result ?? false) || (masterVariantResult ?? false);
};

const hasOutletPrices = (product) => {
  return (
    product?.variants?.some((variant) =>
      variant?.prices?.some((price) => price?.channel?.obj?.key === OUTLET_CHANNEL_KEY),
    ) ||
    product?.masterVariant?.prices?.some((price) => price?.channel?.obj?.key === OUTLET_CHANNEL_KEY)
  );
};

const filterNonOutletProducts = (products) => {
  return products.filter(hasNonOutletPrices);
};

// Filter products with outlet prices and mark them
const filterAndMarkOutletProducts = (products) => {
  return products.filter(hasOutletPrices).map((product) => ({ ...product, outletVersion: true }));
};

// Main function to process products
const processProducts = (products, isOutlet) => {
  const allProducts = flattenProducts(products);
  const nonOutletProducts = filterNonOutletProducts(allProducts);

  if (isOutlet) {
    const outletProducts = filterAndMarkOutletProducts(allProducts);
    return [...nonOutletProducts, ...outletProducts];
  }

  return nonOutletProducts;
};

export default {
  processProducts,
  flattenProducts,
  hasNonOutletPrices,
  hasOutletPrices,
  filterNonOutletProducts,
  filterAndMarkOutletProducts,
};
