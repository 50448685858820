import React, { useState, useEffect } from 'react';
import { arrayOf, bool, shape, string, func } from 'prop-types';
import TextLoader from 'components/TextLoader';
import { DeleteOutline } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useCart, useSetCart } from 'contexts';
import CartTotalsUtils from './PromoSelectionUtils';

const PromoSelection = ({ promo, loading, setLoading, rejectedPromos, setMessage }) => {
  const [isRejected, setIsRejected] = useState(false);
  const cart = useCart();
  const setCart = useSetCart();

  useEffect(() => {
    setIsRejected(CartTotalsUtils.checkRejectedPromos(promo, rejectedPromos));
  }, [rejectedPromos, cart]);

  return (
    <>
      {!isRejected && (
        <Grid container display="flex" justifyContent="space-between">
          <Grid item xs={2}>
            <IconButton
              sx={{ p: 0, mr: 1 }}
              onClick={() =>
                CartTotalsUtils.addToRejectedPromos({
                  cartId: cart?.id,
                  promo,
                  rejectedPromos,
                  setCart,
                  setLoading,
                  setMessage,
                })
              }
            >
              <TextLoader loading={loading} size={20} text={<DeleteOutline />} />
            </IconButton>
          </Grid>
          <Grid item xs={6.5}>
            <Typography textAlign="left" variant="list_item">
              {promo?.display_name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography textAlign="right" variant="list_item">
              -{promo?.totalCumulativeDiscount}
            </Typography>
          </Grid>
        </Grid>
      )}
      {isRejected && (
        <>
          <IconButton
            sx={{ p: 0, mr: 1 }}
            onClick={() =>
              CartTotalsUtils.removeFromRejectedPromos({
                cartId: cart?.id,
                promo,
                setCart,
                rejectedPromos,
                setLoading,
                setMessage,
              })
            }
          >
            <TextLoader loading={loading} size={20} text={<AddIcon />} />
          </IconButton>
          <Typography align="left" variant="list_item" sx={{ textDecoration: 'line-through' }}>
            {promo?.display_name}
          </Typography>
        </>
      )}
    </>
  );
};

PromoSelection.propTypes = {
  setMessage: func.isRequired,
  setLoading: func.isRequired,
  promo: shape({
    id: string,
    display_name: string,
    totalCumulativeDiscount: string,
  }),
  loading: bool,
  rejectedPromos: arrayOf(
    shape({
      id: string,
      display_name: string,
      totalCumulativeDiscount: string,
    }),
  ),
};

PromoSelection.defaultProps = {
  promo: { id: '', display_name: '', totalCumulativeDiscount: '' },
  loading: false,
  rejectedPromos: [],
};

export default PromoSelection;
