export const CART_CARD_CART = 'cart';
export const CART_CARD_DRAFT = 'draft';
export const CART_CARD_ORDER = 'order';
export const OUTLET_CHANNEL_KEY = 'OUTLET';

export default {
  CART_CARD_CART,
  CART_CARD_DRAFT,
  CART_CARD_ORDER,
  OUTLET_CHANNEL_KEY,
};
