import { AddShoppingCartOutlined } from '@mui/icons-material';
import { IconButton, ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { lang, copyText } from 'language';
import { func, shape } from 'prop-types';
import { OUTLET_CHANNEL_KEY } from 'utils/constants';

const ProductSearchResult = ({ product, props, onViewProduct, onQuickAdd }) => {
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      key={product?.id}
      padding={1}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Product Header */}
      <ListItem
        onClick={() => onViewProduct(product)}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" alignContent="left" flexGrow={1}>
          {/* Product Image */}
          <Box
            component="img"
            height="30px"
            width="30px"
            mr={2}
            src={product?.masterVariant?.images[0]?.url || 'https://via.placeholder.com/150'}
            alt={product?.name[lang]}
          />
          {/* Product Text */}
          <Box display="flex" flexDirection="column">
            <Typography variant="body1" fontWeight="bold">
              {product?.outletVersion ? `${OUTLET_CHANNEL_KEY} ` : ''}
              {product?.name[lang]}
            </Typography>
            <Box display="flex" alignItems="center">
              {product?.outletVersion ? (
                <Typography
                  color="error"
                  fontWeight={600}
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  {copyText.Cart.CartTools.finalSale}
                </Typography>
              ) : (
                ''
              )}
            </Box>
          </Box>
          {/* Expand/Collapse Icon */}
        </Box>
        <IconButton onClick={(event) => onQuickAdd(event, product)}>
          <AddShoppingCartOutlined />
        </IconButton>
      </ListItem>
    </Box>
  );
};

ProductSearchResult.propTypes = {
  product: shape({
    id: shape({}),
    masterVariant: shape({
      images: shape([shape({ url: '' })]),
    }),
    name: shape({ [lang]: '' }),
  }).isRequired,
  props: shape({}).isRequired,
  onViewProduct: func.isRequired,
  onQuickAdd: func.isRequired,
};

export default ProductSearchResult;
