import { useState } from 'react';
import {
  Button,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import { copyText } from 'language';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';
import { FeedbackOutlined } from '@mui/icons-material';

const Feedback = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const continueToLink = async () => {
    window.location.href =
      'https://onpurple.atlassian.net/servicedesk/customer/portal/32/group/167';
  };
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  return (
    <>
      <MenuItem onClick={handleOpen}>
        <ListItemIcon>
          <FeedbackOutlined />
        </ListItemIcon>
        {copyText.Feedback.sendFeedback}
      </MenuItem>
      <DialogTemplate
        noButton
        openText={copyText.Feedback.sendFeedback}
        dialogTitle={copyText.Feedback.sendFeedback}
        openBtnMargin={2}
        open={open}
        setOpen={setOpen}
        setMessage={setMessage}
        message={message}
      >
        <DialogContent>
          <Grid container columns={16} display="flex" flexDirection="column">
            <Grid item marginTop={3}>
              <Typography variant="h6">{copyText.Feedback.linkWarning}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            size="large"
            sx={{ paddingLeft: 4, paddingRight: 4 }}
            variant="contained"
            onClick={continueToLink}
          >
            {copyText.Feedback.continue}
          </Button>
        </DialogActions>
      </DialogTemplate>
    </>
  );
};

export default Feedback;
