import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Alert,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { func, bool, shape, string, arrayOf } from 'prop-types';
import {
  useCart,
  useSetCart,
  useSetShowCheckout,
  useSetShowPendingPaymentWarning,
  useShowCheckout,
  useSetOpenChargeAfterModal,
  useSetOpenCheckoutStepper,
  useSetCheckoutStepperActiveStep,
} from 'contexts';
import { copyText } from 'language';
import convertCentToDollar from 'utils/convertCentToDollar';
import multiPayUtils from 'utils/multiPayUtils';
import financialCalculators from 'utils/financialCalculators/financialCalculators';

import TextLoader from 'components/TextLoader';
import ProcessIndicator from 'components/ProcessIndicator/ProcessIndicator';
import CloverWarning from 'components/CloverWarning/CloverWarning';
import FinanceListItem from 'components/FinanceListItem';

import CancelAlert from './CancelAlert';
import PendingPayments from './PendingPayments';
import OrderCreationFailure from './OrderCreationFailure';
import CloverIframe from './CloverIframe';

const currency = '$';
const MultiPayStep = ({
  status,
  setStatus,
  setDisableRepeat,
  showOrderCreationFailure,
  setShowOrderCreationFailure,
  orderCreationError,
  setDisableNextButton,
  payments,
  setPayments,
  showCancelWarning,
  setShowCancelWarning,
  cancelButtonWasClicked,
  setSelectedDeliveryMethods,
  setError,
}) => {
  const cart = useCart();
  const setCart = useSetCart();
  const setCheckoutStepperActiveStep = useSetCheckoutStepperActiveStep();
  const setShowPendingPaymentWarning = useSetShowPendingPaymentWarning();
  const showCheckout = useShowCheckout();
  const setShowCheckout = useSetShowCheckout();
  const setOpenChargeAfterModal = useSetOpenChargeAfterModal();
  const setOpenCheckoutStepper = useSetOpenCheckoutStepper();
  const [customPayment, setCustomPayment] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [remainingTotal, setRemainingTotal] = useState(0);
  const [customAmountError, setCustomAmountError] = useState(null);
  const [checkoutDisabled, setCheckoutDisabled] = useState(false);
  const [voidLoading, setVoidLoading] = useState(false);
  const [showVoidError, setShowVoidError] = useState(false);
  const [showMissingCloverWarning, setShowMissingCloverWarning] = useState(false);
  const [refundTotal, setRefundTotal] = useState('');
  const [showRefund, setShowRefund] = useState(false);
  const [showVirtualTerminal, setShowVirtualTerminal] = useState(false);

  useEffect(() => {
    financialCalculators.populateExchangeRefund(cart, setRefundTotal, setShowRefund);
    multiPayUtils.handleUnpaidTotals(
      cart,
      payments,
      setPaymentAmount,
      setRemainingTotal,
      setCustomPayment,
      setCheckoutDisabled,
    );
  }, [payments, cart]);

  useEffect(() => {
    multiPayUtils.handlePaymentsAndRemainingTotals(
      cart,
      payments,
      setPayments,
      setPaymentAmount,
      setRemainingTotal,
      setCustomPayment,
      setCheckoutDisabled,
    );
  }, [showCheckout, cart]);

  useEffect(() => {
    multiPayUtils.handleShowVirtualTerminal(setShowVirtualTerminal);
    multiPayUtils.handleCloverValidation(setShowMissingCloverWarning);
  }, []);

  useEffect(() => {
    if (remainingTotal === 0) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
  }, [remainingTotal]);

  const handleProcessPayment = () => {
    if (paymentMethod === 'card') {
      multiPayUtils.processPayment({
        cart,
        paymentAmount,
        setStatus,
        setLoading,
        setCart,
        setPayments,
        setShowOrderCreationFailure,
        setShowCancelWarning,
        setError,
      });
    }
    if (paymentMethod === 'purpleFinancing') {
      setOpenCheckoutStepper(false);
      setOpenChargeAfterModal(true);
    }
  };

  return (
    <Box open={showCheckout} data-testid="checkout-button-modal">
      <Typography variant="h5" component="span">
        {copyText.Cart.CheckoutButtons.checkout}
      </Typography>
      <Box pr={1}>
        <Typography variant="h6" align="right">
          {convertCentToDollar(remainingTotal)}
        </Typography>
        <Typography variant="subtitle1" align="right" textTransform="none">
          {copyText.Cart.CheckoutButtons.remainingTotal}
        </Typography>
        {cart?.is_exchange_order && showRefund && (
          <FinanceListItem
            label={copyText.Cart.OrderTotal.refundTotal}
            value={`(${refundTotal})`}
            isPromo
            inCheckout
          />
        )}
      </Box>
      <Box>
        {showMissingCloverWarning && <CloverWarning />}
        <FormControl fullWidth>
          <InputLabel id="payment-method-label">
            {copyText.Cart.CheckoutButtons.paymentMethod}
          </InputLabel>
          <Select
            disabled={remainingTotal === 0}
            sx={{ mb: 3, width: '100%' }}
            value={paymentMethod}
            label={copyText.Cart.CheckoutButtons.paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <MenuItem value="card">{copyText.Cart.CheckoutButtons.card}</MenuItem>
            <MenuItem disabled value="cash">
              {copyText.Cart.CheckoutButtons.cash}
            </MenuItem>
            <MenuItem value="purpleFinancing" disabled={paymentAmount < 100}>
              {copyText.Cart.CheckoutButtons.purpleFinancing}
            </MenuItem>
          </Select>
        </FormControl>
        <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  disabled={remainingTotal === 0}
                  checked={customPayment === false}
                  onChange={() =>
                    multiPayUtils.handlePayInFull({
                      setCustomPayment,
                      setCheckoutDisabled,
                      setPaymentAmount,
                      cart,
                      payments,
                    })
                  }
                />
              }
              label={copyText.Cart.CheckoutButtons.payInFull}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography>{copyText.Cart.CheckoutButtons.customPayment}</Typography>
            <Checkbox
              disabled={payments?.length > 1}
              checked={customPayment === true}
              onChange={() => setCustomPayment(true)}
              data-testid="custom-payment-checkbox"
            />
            <TextField
              InputProps={{
                startAdornment: <Typography>{currency}</Typography>,
              }}
              error={customAmountError}
              type="number"
              placeholder="0.00"
              onChange={(e) =>
                multiPayUtils.handleCustomAmountInput({
                  setPaymentAmount,
                  amount: e.target.value,
                  setCheckoutDisabled,
                  setCustomAmountError,
                  remainingTotal,
                })
              }
              value={customPayment ? paymentAmount : undefined}
              disabled={!customPayment}
              data-testid="custom-payment-input"
            />
          </Box>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle2" textAlign="center">
            {copyText.Cart.CheckoutButtons.splitPaymentLimit}
          </Typography>
          {showVirtualTerminal && paymentMethod === 'card' && (
            <CloverIframe
              disabled={checkoutDisabled || loading || remainingTotal === 0}
              paymentAmount={paymentAmount}
              cart={cart}
              setCart={setCart}
              setPayments={setPayments}
              setStatus={setStatus}
            />
          )}
        </Box>
        {showCancelWarning && (
          <CancelAlert
            setShowCancelWarning={setShowCancelWarning}
            voidLoading={voidLoading}
            showVoidError={showVoidError}
            cancelButtonWasClicked={cancelButtonWasClicked}
            handleClearCart={() =>
              multiPayUtils.clearUnprocessedCart({
                setCart,
                setLoading,
                setCustomPayment,
                setShowCancelWarning,
                setShowVoidError,
                setOpenCheckoutStepper,
                setCheckoutStepperActiveStep,
                setSelectedDeliveryMethods,
              })
            }
            handleCancel={() =>
              multiPayUtils.handleCancel({
                setShowCheckout,
                setVoidLoading,
                setShowVoidError,
                setCustomPayment,
                setShowCancelWarning,
                setShowPendingPaymentWarning,
                setCart,
                cart,
                setOpenCheckoutStepper,
                setCheckoutStepperActiveStep,
                setSelectedDeliveryMethods,
                cancelButtonWasClicked,
              })
            }
          />
        )}
        {payments?.length > 0 && (
          <PendingPayments
            payments={payments}
            setDisableRepeat={setDisableRepeat}
            setShowVoidError={setShowVoidError}
            setShowCancelWarning={setShowCancelWarning}
          />
        )}
        {showOrderCreationFailure && (
          <OrderCreationFailure
            error={orderCreationError}
            handleClose={() => setShowOrderCreationFailure(false)}
          />
        )}
        {remainingTotal === 0 && (
          <Alert severity="success" sx={{ pt: 1, pb: 1 }}>
            <Typography textAlign="center">
              {copyText.Cart.CheckoutButtons.paymentsCompleted}
            </Typography>
          </Alert>
        )}
      </Box>
      <Box>
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}
        >
          <Grid item>
            <ProcessIndicator data-testid="process-status" text={status} />
          </Grid>

          {(!showVirtualTerminal || paymentMethod === 'purpleFinancing') && (
            <Grid item>
              <Button
                disabled={checkoutDisabled || loading || remainingTotal === 0}
                onClick={handleProcessPayment}
                variant="contained"
              >
                <TextLoader
                  text={
                    paymentMethod === 'purpleFinancing'
                      ? copyText.Cart.CheckoutButtons.continueToPurpleFinancing
                      : copyText.Cart.CheckoutButtons.processPayment
                  }
                  loading={loading}
                  size={20}
                />
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default MultiPayStep;

MultiPayStep.propTypes = {
  status: string.isRequired,
  setStatus: func.isRequired,
  setDisableRepeat: func.isRequired,
  showOrderCreationFailure: bool.isRequired,
  setShowOrderCreationFailure: func.isRequired,
  orderCreationError: shape({
    response: shape({
      data: shape({
        errors: arrayOf(
          shape({
            code: func.isRequired,
            detail: func.isRequired,
          }).isRequired,
        ),
      }).isRequired,
    }),
  }),
  setDisableNextButton: func.isRequired,
  payments: arrayOf(
    shape({
      paymentId: string.isRequired,
    }),
  ).isRequired,
  setPayments: func.isRequired,
  showCancelWarning: bool.isRequired,
  setShowCancelWarning: func.isRequired,
  cancelButtonWasClicked: bool.isRequired,
  setSelectedDeliveryMethods: func.isRequired,
  setError: func.isRequired,
};

MultiPayStep.defaultProps = {
  orderCreationError: null,
};
