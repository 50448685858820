import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useIsOutlet, useShopProducts } from 'contexts';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import fuzzySearchUtils from 'utils/fuzzySearchUtils';
import { copyText, lang } from 'language';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';

import ProductSearchResult from './components/ProductSearchResult';
import searchBarUtils from './SearchBar.utils';
import QuickAddModal from './components/QuickAddModal';

const SearchBar = () => {
  const isOutlet = useIsOutlet();
  const history = useHistory();
  const products = useShopProducts();
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [highlightedProduct, setHighlightedProduct] = useState(null); // Track highlighted product
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showProduct, setShowProduct] = useState(false);
  const [message, setMessage] = useState('');

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.trim() === '') {
      setFilteredProducts([]);
    } else {
      const filtered = fuzzySearchUtils.fuzzySearchAllProducts(newInputValue, allProducts);
      setFilteredProducts(filtered);
    }
  };

  const onAddToCart = () => {
    setMessage(copyText.Shop.PDP.addToCart.success);
    setShowProduct(false);
  };

  const handleViewProduct = (product) => {
    const { categoryId, id } = product;
    const shopPath = product?.outletVersion ? 'outlet' : 'retail';
    const newPath = `/shop/${shopPath}/category/${categoryId}/product/${id}`;

    history.push(newPath);
    window.location.href = newPath;
  };

  const handleQuickAdd = (event, product) => {
    event.stopPropagation();
    setSelectedProduct(product);
    setShowProduct(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && highlightedProduct) {
      handleViewProduct(highlightedProduct);
    }
  };

  useEffect(() => {
    if (products) {
      const processedProducts = searchBarUtils.processProducts(products, isOutlet);
      setAllProducts(processedProducts);
    }
  }, [products, isOutlet]);

  return (
    <>
      <Autocomplete
        freeSolo
        options={filteredProducts}
        getOptionLabel={(product) => product?.name?.[lang] ?? inputValue} // Use product name as the label
        inputValue={inputValue}
        onHighlightChange={(event, option) => setHighlightedProduct(option)} // Track highlighted item
        onInputChange={handleInputChange}
        sx={{
          width: '100%',
        }}
        renderOption={(props, product) => (
          <ProductSearchResult
            props={props}
            product={product}
            onViewProduct={handleViewProduct}
            onQuickAdd={handleQuickAdd}
          />
        )}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            size="small"
            type="text"
            placeholder={copyText.Shop.Search.searchProducts}
            onKeyDown={handleKeyDown}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              borderRadius: '20px',
            }}
            fullWidth
          />
        )}
      />
      <QuickAddModal
        showProduct={showProduct}
        setShowProduct={setShowProduct}
        selectedProduct={selectedProduct}
        onAddToCart={onAddToCart}
      />
      <SnackbarMessage message={message} setMessage={setMessage} />
    </>
  );
};

export default SearchBar;
