import { func, shape, string, arrayOf, number, oneOfType } from 'prop-types';
import { Grid, Card, List, ListItem, CardMedia, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import convertCentToDollar from 'utils/convertCentToDollar';
import addToCartBtnUtils from 'components/AddToCartBtn/addToCartBtnUtils';

const EditCartCard = ({ variant, handleSelection, selectedVariant, productName, channelId }) => {
  const [price, setPrice] = useState('0.00');
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [variantAttributes, setVariantAttributes] = useState([]);
  const formatAttributes = (attributes) => {
    const resp = {};
    attributes.forEach((attr) => {
      resp[attr.name] = attr.value;
    });
    setVariantAttributes(resp);
  };

  const populatePriceByChannel = () => {
    const channelPrice = variant?.prices?.find(
      (variantPrice) => variantPrice?.channel?.id === channelId,
    );
    const selectedPrice = channelPrice?.value?.centAmount ?? variant.prices[0]?.value?.centAmount;
    setPrice(convertCentToDollar(selectedPrice));
  };

  useEffect(() => {
    formatAttributes(variant.attributes);
    populatePriceByChannel();
    addToCartBtnUtils.setOutOfStock({
      setIsOutOfStock,
      details: variant,
    });
  }, []);
  return (
    <Card
      sx={{
        backgroundColor: 'transparent',
        width: '100%',
        cursor: isOutOfStock ? 'not-allowed' : 'pointer', // Indicate non-clickable state visually
        opacity: isOutOfStock ? 0.5 : 1, // Dim the card if out of stock
      }}
      variant={variant.sku === selectedVariant.sku ? 'outlined' : ''}
      onClick={() => {
        if (!isOutOfStock) {
          handleSelection(variant);
        }
      }}
      data-testid="edit-cart-card"
    >
      <Grid container columns={16} display="flex" flexDirection="row">
        <Grid
          p={1}
          item
          xs={16}
          sm={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CardMedia component="img" src={variant?.images[0]?.url} alt={productName} />
        </Grid>
        <Grid item xs={16} sm={10} display="flex" alignItems="left" flexDirection="column">
          <List disablePadding>
            <ListItem
              disableGutters
              sx={{ display: 'flex', mt: 0, p: 0, justifyContent: 'space-between' }}
            >
              <Typography align="left" component="p" variant="h6">
                {variantAttributes.size?.label}
                {variantAttributes['pillow-height']?.label}
              </Typography>
              <Typography align="right" component="p" variant="h6" color="secondary">
                {price}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <Typography component="p" variant="h3">
                {variantAttributes.color?.label}
                {variantAttributes['pillow-size']?.label}
                {variantAttributes['cover-type']?.label}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <Typography variant="p">{variantAttributes.gender?.label}</Typography>
            </ListItem>
            {isOutOfStock && (
              <ListItem disableGutters>
                <Typography color="error" variant="body2">
                  Out of stock
                </Typography>
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    </Card>
  );
};

EditCartCard.propTypes = {
  productName: string.isRequired,
  channelId: string,
  selectedVariant: shape({
    sku: string.isRequired,
  }).isRequired,
  handleSelection: func.isRequired,
  variant: shape({
    prices: arrayOf(
      shape({
        value: shape({
          centAmount: number.isRequired,
        }).isRequired,
      }).isRequired,
    ),
    sku: string.isRequired,
    images: arrayOf(
      shape({
        url: string.isRequired,
      }).isRequired,
    ).isRequired,
    attributes: arrayOf(
      shape({
        value: oneOfType([
          string.isRequired,
          shape({
            label: string.isRequired,
          }).isRequired,
        ]),
      }),
    ),
  }).isRequired,
};

EditCartCard.defaultProps = {
  channelId: null,
};

export default EditCartCard;
