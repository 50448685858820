import { moveToTopOfHistory } from 'utils/cartHistory';
import cartService from 'dataAccess/api/cart.ts';

const buildQuery = ({ formData }) => {
  const { customerEmail, draftsFilter, activeCarts, store } = formData;

  // Helper to handle customer email queries
  const getCustomerEmailQuery = () => {
    if (!customerEmail) return '';
    return `(customerEmail = "${customerEmail}" or createdBy(externalUserId = "${customerEmail}"))`;
  };

  // Helper for drafts filter
  const getDraftsFilterQuery = () =>
    draftsFilter ? `custom(fields(is_draft_order = ${draftsFilter}))` : '';

  // Helper for store location query
  const getStoreLocationQuery = () => {
    if (!store || store === 'all') return '';
    return `store(key = "${store}")`;
  };

  // Special case for active cart IDs
  const getActiveCartsQuery = () => (activeCarts ? `id in (${activeCarts})` : '');

  // Build the query conditions dynamically
  const conditions = [];

  if (customerEmail) {
    conditions.push('cartState = "Active"');
    conditions.push(getCustomerEmailQuery());
  }

  if (activeCarts) {
    conditions.push(getActiveCartsQuery());
  }

  if (store && store !== 'all') {
    conditions.push(getStoreLocationQuery());
  }

  if (draftsFilter) {
    conditions.push(getDraftsFilterQuery());
  }

  // Combine conditions with "and" if any exist, else return an empty string
  return conditions.length > 0 ? conditions.join(' and ') : '';
};

const submitSearchQuery = async ({
  formData,
  page = formData.page,
  draftsFilter = formData.draftsFilter,
  setFormData,
  setCurrentPage,
  setLoading,
  setFoundCarts,
  setTotalResults,
  setStatus,
}) => {
  setFormData((prev) => ({ page, draftsFilter, ...prev }));
  setCurrentPage(page);
  const query = buildQuery({
    formData: {
      ...formData,
      page,
      draftsFilter,
      customerEmail: formData.customerEmail,
    },
  });
  try {
    setLoading(true);
    const payload = {
      filter: query,
      sortBy: 'createdAt',
      page: page + 1,
    };
    const response = await cartService.searchCart(payload);
    if (response.results) {
      setFoundCarts(response.results);
      setTotalResults(response.total);
    }
  } catch (error) {
    setStatus(error.message);
  } finally {
    setLoading(false);
  }
};

const handleSetCart = async ({ setLoadingCart, history, setCart, setOldCart, oldCart }) => {
  try {
    setLoadingCart(true);
    await setCart(oldCart);
    // set the location of the cart to the curren location
    moveToTopOfHistory(oldCart.id);
    history.push('/cart');
  } catch (error) {
    setLoadingCart(false);
  } finally {
    setLoadingCart(false);
    setOldCart({});
  }
};

const updateForm = (e, setFormData) => {
  // TODO: Add form validation
  let value = e.target.value;
  const key = e.target.getAttribute('name');
  if (e.target.type === 'checkbox') {
    value = e.target.checked;
  }

  setFormData((prev) => ({
    ...prev,
    [key]: value,
  }));
};

const handleReset = async ({
  defaultFormData,
  setFormData,
  setSelectedAgent,
  setCurrentPage,
  setLoading,
  setFoundCarts,
  setTotalResults,
  setStatus,
  submit = submitSearchQuery,
}) => {
  setFormData(defaultFormData);
  setSelectedAgent(null);
  await submit({
    formData: defaultFormData,
    page: 0,
    setFormData,
    setSelectedAgent,
    setCurrentPage,
    setLoading,
    setFoundCarts,
    setTotalResults,
    setStatus,
  });
};

const handleSubmit = async ({
  event,
  formData,
  setFormData,
  setFoundCarts,
  setTotalResults,
  setLoading,
  setCurrentPage,
  setStatus,
  submit = submitSearchQuery,
}) => {
  event.preventDefault();
  await submit({
    page: 0,
    setFormData,
    formData,
    setFoundCarts,
    setTotalResults,
    setLoading,
    setCurrentPage,
    setStatus,
  });
};

export default {
  submitSearchQuery,
  handleSetCart,
  updateForm,
  handleReset,
  handleSubmit,
  buildQuery,
};
