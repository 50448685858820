import convertCentToDollar from 'utils/convertCentToDollar';
import ProductServices from 'dataAccess/pimApi/product.ts';

export const _checkIfDuplicate = ({ attribute, updatedVariants }) => {
  if (attribute?.value) {
    return updatedVariants[attribute.name].some((item) => {
      if (typeof item.value === 'string' && typeof attribute.value === 'string') {
        return item.value === attribute.value;
      }
      if (typeof item.value === 'object' && typeof attribute.value === 'object') {
        return item.value.key === attribute.value.key;
      }
      return false;
    });
  }
  return false;
};

export const _addAttribute = ({ attribute, variantsList, sku }) => {
  if (!attribute) {
    return variantsList;
  }

  const updatedVariants = { ...variantsList };
  const { name, value } = attribute;

  if (!updatedVariants[name]) {
    updatedVariants[name] = [];
  }

  if (value === undefined) {
    return updatedVariants;
  }

  const existingVariant = updatedVariants[name].find((variant) => {
    if (typeof value === 'object') {
      return JSON.stringify(variant.value) === JSON.stringify(value);
    }
    return variant.value === value;
  });

  if (existingVariant) {
    if (!existingVariant.availableSkus.includes(sku)) {
      existingVariant.availableSkus.push(sku);
    }
  } else {
    updatedVariants[name].push({ value, availableSkus: [sku] });
  }

  return updatedVariants;
};

export const _mapAttributesToVariants = ({ variants, attributesList, sku }) => {
  let updatedVariants = JSON.parse(JSON.stringify(variants));

  if (attributesList && Array.isArray(attributesList)) {
    attributesList.forEach((attribute) => {
      if (attribute) {
        updatedVariants = _addAttribute({ attribute, variantsList: updatedVariants, sku });
      }
    });
  }

  return updatedVariants;
};

export const _removeDuplicates = (variantsList) => {
  const updatedVariants = JSON.parse(JSON.stringify(variantsList));
  Object.keys(updatedVariants).forEach((key) => {
    updatedVariants[key] = [...new Set(updatedVariants[key])];
  });
  return updatedVariants;
};

export const _mapVariantAttributesToVariants = ({ variants, variantsList, selectedSku }) => {
  if (!variants && variantsList.length === 0 && !selectedSku) {
    return [];
  }
  let updatedVariants = JSON.parse(JSON.stringify(variants));
  variantsList.forEach((variant) => {
    if (variant?.attributes) {
      updatedVariants = _mapAttributesToVariants({
        variants: updatedVariants,
        attributesList: variant?.attributes,
        sku: variant?.sku,
      });
    }
  });
  updatedVariants = _removeDuplicates(updatedVariants);
  return updatedVariants;
};

export const getProductDetails = async (productId) => {
  if (productId) {
    try {
      const productDetails = await ProductServices.getProductById(productId);
      return productDetails;
    } catch (err) {
      throw new Error(err);
    }
  }
  return null;
};

export const populateProductDetails = async ({ categoryId, productId, setProductDetailsMap }) => {
  const productDetails = await getProductDetails(productId);

  setProductDetailsMap((prevMap) => {
    const newMap = {
      ...prevMap,
    };
    if (productDetails) {
      const { id: prodId } = productDetails;
      if (newMap[categoryId]) {
        newMap[categoryId][prodId] = productDetails;
      } else {
        newMap[categoryId] = {};
        newMap[categoryId][prodId] = productDetails;
      }
    }
    return newMap;
  });
};

export const findVariant = (product, sku) => {
  if (product.variants && product.variants.length > 0) {
    const foundVariant = product.variants.find((variant) => variant.sku === sku);
    if (foundVariant) {
      return foundVariant;
    }
  }

  return product.masterVariant;
};

export const _findVariantPrice = (variant, channelKey) => {
  if (variant?.prices?.length > 0) {
    const foundPrice = variant?.prices.find((price) => price?.channel?.obj?.key === channelKey);
    if (foundPrice) {
      return foundPrice?.value.centAmount;
    }
    return variant?.prices[0]?.value.centAmount;
  }
  return null;
};

export const populateProduct = ({
  categoryId,
  productId,
  variantSku,
  productDetailsMap,
  setProduct,
  setSelectedVariant,
}) => {
  if (
    productDetailsMap &&
    productDetailsMap[categoryId] &&
    productDetailsMap[categoryId][productId]
  ) {
    const product = productDetailsMap[categoryId][productId];

    if (product) {
      setProduct(product);

      if (variantSku) {
        const variant = findVariant(product, variantSku);
        setSelectedVariant(variant);
      } else {
        setSelectedVariant(product?.masterVariant);
      }
    }
  }
};

export const populatePricing = ({ variant, channelKey, setVariantPrice }) => {
  if (variant) {
    const price = _findVariantPrice(variant, channelKey);
    const priceDollars = convertCentToDollar(price);
    setVariantPrice(priceDollars);
  }
};

export const populateAttributes = ({ variant, setAttributes }) => {
  const attributes = {};

  if (variant) {
    variant?.attributes.forEach((attribute) => {
      attributes[attribute.name] = attribute.value;
    });
    setAttributes(attributes);
  }
};

export const populateAvailability = ({ variant, setAvailability }) => {
  if (variant) {
    setAvailability(variant.availability);
  }
};

export const populateVariants = ({ product, variantSku, setSelectedVariant, setVariants }) => {
  let variants = {};

  if (product) {
    const foundVariant = findVariant(product, variantSku);
    setSelectedVariant(foundVariant || product?.masterVariant);

    variants = _mapAttributesToVariants({
      variants,
      attributesList: JSON.parse(JSON.stringify(product?.masterVariant?.attributes)),
      sku: foundVariant?.sku,
    });

    if (product?.variants) {
      variants = _mapVariantAttributesToVariants({
        variants,
        variantsList: JSON.parse(JSON.stringify(product?.variants)),
        selectedSku: foundVariant?.sku,
      });
    }
  }
  setVariants(variants);
};

export const getSkuByAttributes = ({ product, attributes }) => {
  let result = null;

  // Combine masterVariant and variants into a single array, filtering out any null values
  const allVariants = [product?.masterVariant, ...product.variants].filter(Boolean);
  // If there are no attributes, return the masterVariant sku
  if (attributes) {
    // Loop through all variants and check if all attributes match
    allVariants.forEach((item) => {
      const allAttributesMatch = Object.keys(attributes).every((key) => {
        const attribute = item.attributes.find((attr) => attr.name === key);
        if (attribute) {
          // If the attribute value is an object, check the key
          if (typeof attribute.value === 'object') {
            // If the attribute value is an object with a key, check the key
            return attribute.value.key === attributes[key];
          }
          // If the attribute value is a string, check the value
          return attribute.value === attributes[key];
        }
        // If the attribute is not found, return false
        return false;
      });

      if (allAttributesMatch) {
        result = item.sku;
      }
    });
  }

  return result;
};

export const _formatFormData = (formData) => {
  const variantTypes = [
    'size',
    'color',
    'pillow-size',
    'pillow-height',
    'duvet-type',
    'gender',
    'depth',
    'feel',
  ];
  // only return entries that are from the variantTypes
  const filteredData = Object.entries(formData).filter(([key]) => variantTypes.includes(key));
  return Object.fromEntries(filteredData);
};

export const populateForm = ({ product, selectedVariant, setFormData }) => {
  if (product && selectedVariant) {
    const formData = {};

    selectedVariant.attributes.forEach((attribute) => {
      if (
        attribute.value &&
        typeof attribute.value === 'object' &&
        'key' in attribute.value &&
        'label' in attribute.value
      ) {
        formData[attribute.name] = attribute.value.key;
      }
    });
    const formattedData = _formatFormData(formData);
    setFormData(formattedData);
  }
};
