import { copyText } from 'language';
import theme from 'theme';
import storeLocation from 'utils/storeLocation';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import convertDollarToCent from 'utils/convertDollarToCent';
import multiPayUtils from 'utils/multiPayUtils';
import orders from 'dataAccess/api/orders.ts';

const cloverIframeStyles = {
  body: {
    display: 'flex',
  },
  input: {
    fontSize: theme.theme.typography.h6.fontSize,
    margin: theme.theme.spacing(0.5),
    border: `1px solid ${theme.theme.palette.primary.main}`,
    borderRadius: theme.theme.shape.borderRadius,
    padding: theme.theme.spacing(2),
  },
};

const loadCloverScript = ({ setCloverReady, setMessage }) => {
  const scriptSrc = process.env.REACT_APP_CLOVER_IFRAME_URL;

  if (!scriptSrc) {
    setMessage(copyText.Cart.CheckoutStepper.errorLoadingCloverScript);
    return;
  }

  // Check if the script already exists
  if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
    const script = document.createElement('script');
    script.src = `${scriptSrc}?timestamp=${Date.now()}`; // Adding timestamp to bypass cache
    script.async = true;

    script.onerror = () => {
      setMessage(copyText.Cart.CheckoutStepper.errorLoadingCloverScript);
    };

    document.body.appendChild(script);
    setCloverReady(true);
  } else {
    setCloverReady(true);
  }
};
// Function to load the Clover iframe
const loadIframe = ({ cloverContainerId, setMessage }) => {
  try {
    const storeDetails = storeLocation.getLocationStorage();
    const merchantId = storeDetails?.custom?.fields?.cloverMerchantId;
    const cloverPublicKey = storeDetails?.custom?.fields?.cloverPublicKey;
    const clover = new window.Clover(cloverPublicKey, {
      merchantId,
    });

    const elements = clover.elements();
    const cardElement = elements.create('CARD', cloverIframeStyles);
    cardElement.mount(`#${cloverContainerId}`);

    // Return the clover instance with cardElement
    return { clover, cardElement };
  } catch (err) {
    setMessage(copyText.Cart.CheckoutStepper.errorLoadingClover);
  }
  return null;
};

// Function to handle payment processing when manually entering card details
const handleProcessPayment = async ({
  cloverInstanceRef,
  cart,
  paymentAmount,
  setMessage,
  setCart,
  setPayments,
  setStatus,
  setLoading,
}) => {
  setLoading(true);
  try {
    const { clover, cardElement } = cloverInstanceRef?.current ?? {};
    if (clover && cardElement) {
      const result = await clover.createToken(cardElement);

      if (result && result.token) {
        const centAmount =
          paymentAmount === 0
            ? financialCalculators.getTotalPrice(cart)
            : convertDollarToCent(paymentAmount);
        const storeKey = cart?.store?.key;
        const chargeResult = await orders.chargeCloverToken({
          cartId: cart.id,
          token: result.token,
          centAmount,
          storeKey,
        });

        const payment = { ...chargeResult?.data, last4: chargeResult?.data?.source?.last4 };
        multiPayUtils.handleSuccessfulPayment({
          payment,
          cart,
          setCart,
          setPayments,
          setStatus,
        });
      } else {
        setMessage(copyText.Cart.CheckoutStepper.errorProcessingPayment);
      }
    } else {
      setMessage(copyText.Cart.CheckoutStepper.errorProcessingPayment);
    }
  } catch (err) {
    setMessage(copyText.Cart.CheckoutStepper.errorProcessingPayment);
  } finally {
    setLoading(false);
  }
};

const unloadCloverIframe = (cloverInstance) => {
  if (cloverInstance && cloverInstance.parentNode) {
    // Remove the iframe from the DOM
    cloverInstance.parentNode.removeChild(cloverInstance);
  }
};

export default {
  loadCloverScript,
  loadIframe,
  handleProcessPayment,
  unloadCloverIframe,
};
