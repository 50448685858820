import { React, useEffect, useState } from 'react';
import { Grid, Typography, Breadcrumbs, Link, Skeleton } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { copyText, lang } from 'language';
import {
  useCart,
  useShopCategories,
  useShopProducts,
  useIsOutlet,
  useSelectedShop,
} from 'contexts';
import AppBanner from 'components/AppBanner/AppBanner';
import orders from 'dataAccess/api/orders.ts';
import { getBaseUrl } from 'utils/shopUtils';
import { OUTLET_CHANNEL_KEY } from 'utils/constants';

const ShopHeader = () => {
  const cart = useCart();
  const [shopBreadcrumbs, setShopBreadcrumbs] = useState({});
  const { categoryId, productId, variantSku } = useParams();
  const [showBanner, setShowBanner] = useState(false);
  const [exchangeOrderNumber, setExchangeOrderNumber] = useState(null);
  const shopCategories = useShopCategories();
  const shopProducts = useShopProducts();
  const isOutlet = useIsOutlet();
  const shopSelected = useSelectedShop();
  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    setBaseUrl(getBaseUrl(shopSelected));
  }, [isOutlet]);

  const getCategory = () => {
    let resp = null;
    if (categoryId && shopCategories) {
      const category = shopCategories.find((c) => c.id === categoryId);
      resp = {
        name: category?.name[lang],
      };
    }
    return resp;
  };

  const findProduct = (products, prodId) => {
    return products.find((item) => item.id === prodId);
  };

  const getProduct = () => {
    let resp = null;
    if (productId && shopProducts) {
      const products = shopProducts[categoryId];
      const product = findProduct(products, productId);
      resp = {
        name: product?.name[lang],
      };
    }
    return resp;
  };

  const findVariant = (product, varSku) => {
    let resp;
    if (product?.masterVariant.sku === varSku) {
      resp = product.masterVariant;
    } else {
      resp = product.variants.find((item) => item.sku === varSku);
    }

    return resp;
  };

  const getVariant = () => {
    let resp = null;
    if (variantSku && shopProducts) {
      const products = shopProducts[categoryId];
      const product = findProduct(products, productId);
      const variant = findVariant(product, variantSku);
      const title = variant.attributes?.find((item) => item.name === 'title');
      resp = {
        name: title?.value || copyText.Shop.ShopHeader.variant,
      };
    }
    return resp;
  };

  const setCategory = () => {
    const category = getCategory();
    if (category) {
      setShopBreadcrumbs((prev) => ({
        ...prev,
        currentCategory: {
          label: category.name,
          uri: `${baseUrl}/category/${categoryId}`,
        },
      }));
    }
  };

  useEffect(() => {
    setCategory();
  }, [categoryId, productId, variantSku, shopProducts, shopCategories]);

  const setProduct = () => {
    const product = getProduct();
    let newCurrent = null;
    if (product) {
      newCurrent = {
        label: product.name,
        uri: `${baseUrl}/category/${categoryId}/product/${productId}`,
      };
    }
    setShopBreadcrumbs((prev) => ({
      ...prev,
      currentProduct: newCurrent,
    }));
  };

  useEffect(() => {
    setProduct();
  }, [categoryId, productId]);

  const setVariant = () => {
    const variant = getVariant();
    if (variant) {
      setShopBreadcrumbs((prev) => ({
        ...prev,
        currentVariant: {
          label: variant.name,
        },
      }));
    } else {
      setShopBreadcrumbs((prev) => ({
        ...prev,
        currentVariant: null,
      }));
    }
  };
  const populateExchangeOrderNumber = async (orderId) => {
    const result = await orders.getOrderById(orderId);
    setExchangeOrderNumber(result?.orderNumber);
  };

  useEffect(() => {
    if (cart && cart?.is_exchange_order) {
      populateExchangeOrderNumber(cart.original_order_id);
      setShowBanner(true);
    }
  }, [cart?.id]);

  useEffect(() => {
    setVariant();
  }, [variantSku]);

  return (
    <Grid sx={{ m: 'auto auto', mb: 1 }}>
      {showBanner && (
        <AppBanner
          message={`${copyText.Orders.Actions.exchangeInstructions.exchangeCartWarning}${
            exchangeOrderNumber ?? ''
          }`}
          severity="warning"
        />
      )}
      <Typography variant="h1">
        {shopSelected === OUTLET_CHANNEL_KEY
          ? copyText.Shop.ShopHeader.headingOutlet
          : copyText.Shop.ShopHeader.heading}
      </Typography>

      <Breadcrumbs pb={3}>
        {/* --- crumb 1 --- */}
        {categoryId && shopBreadcrumbs.currentCategory ? (
          <Link
            component={RouterLink}
            to={baseUrl}
            underline="hover"
            color={variantSku ? 'text.secondary' : 'text.primary'}
          >
            {copyText.Shop.ShopHeader.breadcrumbs.root}
          </Link>
        ) : (
          <Typography>{copyText.Shop.ShopHeader.breadcrumbs.root}</Typography>
        )}

        {/* --- crumb 2 --- */}
        {categoryId && shopBreadcrumbs.currentCategory && (
          <Link
            component={RouterLink}
            to={`${baseUrl}/category/${categoryId}`}
            underline="hover"
            color={productId ? 'text.secondary' : 'text.primary'}
          >
            {shopBreadcrumbs.currentCategory.label}
          </Link>
        )}

        {/* --- crumb 3 --- */}
        {productId && shopBreadcrumbs.currentProduct && (
          <Link
            component={RouterLink}
            to={`${baseUrl}/category/${categoryId}/product/${productId}`}
            underline="hover"
            color={variantSku ? 'text.secondary' : 'text.primary'}
          >
            {shopBreadcrumbs.currentProduct.label}
          </Link>
        )}

        {/* --- crumb 4 --- */}
        {variantSku && (
          <Typography color="text.primary">{shopBreadcrumbs.currentVariant?.label}</Typography>
        )}

        {/* --- skeleton loader --- */}
        {!shopBreadcrumbs.currentCategory &&
          !shopBreadcrumbs.currentProduct &&
          !shopBreadcrumbs.currentVariant &&
          categoryId && <Skeleton width={160} height={40} />}
      </Breadcrumbs>
    </Grid>
  );
};

export default ShopHeader;
