import { lang } from 'language';

const levenshteinDistance = (source, target) => {
  const sourceLength = source.length;
  const targetLength = target.length;

  // Initialize a 2D matrix to store distances
  const distanceMatrix = Array.from({ length: sourceLength + 1 }, (row, i) =>
    Array.from({ length: targetLength + 1 }, (col, j) => (i === 0 ? j : i)),
  );

  // Populate the matrix using Levenshtein logic
  for (let i = 1; i <= sourceLength; i++) {
    for (let j = 1; j <= targetLength; j++) {
      if (source[i - 1] === target[j - 1]) {
        distanceMatrix[i][j] = distanceMatrix[i - 1][j - 1];
      } else {
        distanceMatrix[i][j] = Math.min(
          distanceMatrix[i - 1][j] + 1, // Deletion
          distanceMatrix[i][j - 1] + 1, // Insertion
          distanceMatrix[i - 1][j - 1] + 1, // Substitution
        );
      }
    }
  }

  // Return the final value (edit distance)
  return distanceMatrix[sourceLength][targetLength];
};

const calculateSimilarity = (query, target) => {
  // Calculate the Levenshtein distance
  const distance = levenshteinDistance(query.toLowerCase(), target.toLowerCase());
  // Normalize the distance
  const maxLength = Math.max(query.length, target.length);
  // Return the similarity score
  return 1 - distance / maxLength;
};

const fuzzySearchAllProducts = (query, products, threshold = 0.5) => {
  // Map products with their similarity scores
  const rankedProducts = products
    .map((product) => {
      const nameChunks = product.name[lang].split(/\s+/); // Split the name into chunks by whitespace
      const maxSimilarity = Math.max(
        ...nameChunks.map((chunk) => calculateSimilarity(query, chunk)), // Calculate similarity for each chunk
      );

      return {
        product,
        score: maxSimilarity,
      };
    })
    .filter(({ score }) => score >= threshold) // Filter out products below the threshold
    .sort((a, b) => b.score - a.score); // Sort by similarity score in descending order

  // Return only the products in ranked order
  return rankedProducts.map(({ product }) => product);
};

export default {
  levenshteinDistance,
  calculateSimilarity,
  fuzzySearchAllProducts,
};
