import { Chip, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AddToCartBtn from 'components/AddToCartBtn/AddToCartBtn';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';
import { copyText, lang } from 'language';
import { bool, func, shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { OUTLET_CHANNEL_KEY } from 'utils/constants';
import Variants from 'views/Shop/views/NewPDP/components/VariantsBlock';
import {
  getSkuByAttributes,
  populateForm,
  populatePricing,
  populateVariants,
} from 'views/Shop/views/NewPDP/PDP.utils';

const QuickAddModal = ({ showProduct, setShowProduct, selectedProduct, onAddToCart }) => {
  const [selectedVariant, setSelectedVariant] = useState();
  const [variants, setVariants] = useState();
  const [formData, setFormData] = useState({});
  const [variantPrice, setVariantPrice] = useState();

  const handleChangeSelection = (e, key) => {
    const value = e.target.value;
    const data = { ...formData, [key]: value };

    const sku = getSkuByAttributes({ product: selectedProduct, attributes: data });
    if (sku) {
      populateVariants({
        product: selectedProduct,
        variantSku: sku,
        setSelectedVariant,
        setVariants,
      });
    }
  };

  useEffect(() => {
    populateForm({ product: selectedProduct, selectedVariant, setFormData });
  }, [selectedVariant]);

  useEffect(() => {
    populateVariants({ product: selectedProduct, setSelectedVariant, setVariants });
  }, [selectedProduct]);

  useEffect(() => {
    populatePricing({
      variant: selectedVariant,
      channelKey: selectedProduct?.outletVersion ? OUTLET_CHANNEL_KEY : '',
      setVariantPrice,
    });
    populateForm({ product: selectedProduct, selectedVariant, setFormData });
  }, [selectedVariant, selectedProduct]);

  return (
    <DialogTemplate
      noButton
      dialogTitle={copyText.Shop.PDP.addToCart.quickAddTitle}
      open={showProduct}
      onClose={() => setShowProduct(false)}
    >
      <DialogContent>
        <Grid container spacing={2} my={1} alignItems="flex-start">
          <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
            <Box
              component="img"
              sx={{
                width: '100%',
                maxWidth: 150,
              }}
              src={selectedVariant?.images?.[0]?.url}
              alt="Product Image"
            />
            <Typography variant="h6" mt={2}>
              {variantPrice}
            </Typography>
          </Grid>

          <Grid item xs={8} display="flex" flexDirection="column">
            <Typography variant="h6" mt={1}>
              {selectedProduct?.outletVersion ? OUTLET_CHANNEL_KEY : ''}{' '}
              {selectedProduct?.name?.[lang]}
            </Typography>

            <Typography sx={{ my: 1 }} variant="body1" color="textSecondary" alignItems="center">
              {selectedVariant?.sku}
              {selectedProduct?.outletVersion && (
                <Chip
                  sx={{ ml: 1 }}
                  size="small"
                  label={copyText.Cart.CartTools.finalSale}
                  color="error"
                />
              )}
            </Typography>

            <Variants
              variants={variants}
              formData={formData}
              handleChange={handleChangeSelection}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <AddToCartBtn
          details={selectedVariant}
          searchSelectedShop={selectedProduct?.outletVersion ? OUTLET_CHANNEL_KEY : null}
          addFromSearch
          suppressMessage
          onComplete={onAddToCart}
        />
      </DialogActions>
    </DialogTemplate>
  );
};

QuickAddModal.propTypes = {
  showProduct: bool.isRequired,
  setShowProduct: func.isRequired,
  selectedProduct: shape({}),
  onAddToCart: func.isRequired,
};

QuickAddModal.defaultProps = {
  selectedProduct: null,
};

export default QuickAddModal;
