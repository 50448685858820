import { bool, func, shape, number } from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { Button, Box, Typography } from '@mui/material';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';
import { copyText } from 'language';
import TextLoader from 'components/TextLoader';
import cloverIframeUtils from './utils/cloverIframeUtils';

const CloverIframe = ({ disabled, paymentAmount, cart, setCart, setPayments, setStatus }) => {
  const cloverContainerId = 'clover-container';
  const [cloverReady, setCloverReady] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const cloverInstanceRef = useRef(null);

  const processPayment = async () => {
    cloverIframeUtils.handleProcessPayment({
      cloverInstanceRef,
      cart,
      paymentAmount,
      setMessage,
      setCart,
      setPayments,
      setStatus,
      setLoading,
    });
  };

  // Effect to load the Clover script only once
  useEffect(() => {
    cloverIframeUtils.loadCloverScript({
      setCloverReady,
      setMessage,
    });
    return () => {
      if (cloverInstanceRef.current) {
        cloverIframeUtils.unloadCloverIframe(cloverInstanceRef.current);
        cloverInstanceRef.current = null;
      }
    };
  }, []);

  // Load the iframe when Clover is ready
  useEffect(() => {
    if (cloverReady && !cloverInstanceRef.current) {
      const instance = cloverIframeUtils.loadIframe({
        cloverContainerId,
        setMessage,
      });
      if (instance) {
        cloverInstanceRef.current = instance;
      }
    }
  }, [cloverReady]);

  return (
    <Box sx={{ mb: 3, mt: 3 }}>
      <Typography variant="h6">{copyText.Cart.CheckoutStepper.enterCardDetails}</Typography>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: 1,
          height: '200px',
          mt: 2,
        }}
        id={cloverContainerId}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          disabled={!cloverReady || disabled || loading}
          onClick={processPayment}
          variant="contained"
          sx={{ mt: 4 }}
        >
          <TextLoader
            loading={loading}
            text={copyText.Cart.CheckoutStepper.processPayment}
            size={20}
          />
        </Button>
      </Box>
      <SnackbarMessage message={message} setMessage={setMessage} />
    </Box>
  );
};

CloverIframe.propTypes = {
  disabled: bool.isRequired,
  cart: shape({}),
  paymentAmount: number,
  setCart: func.isRequired,
  setPayments: func.isRequired,
  setStatus: func.isRequired,
};

CloverIframe.defaultProps = {
  cart: {},
  paymentAmount: 0,
};

export default CloverIframe;
