import { useState, createContext, useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import ProductServices from 'dataAccess/pimApi/product.ts';
import CartService from 'dataAccess/api/cart.ts';
import getAccessToken from 'utils/getAccessToken';
import { getMostRecentCart } from 'utils/cartHistory';

const useStore = () => {
  const [oldCart, setOldCart] = useState({});
  const [cart, setCart] = useState();
  const [showShippingSnackbar, setShowShippingSnackbar] = useState(false);
  const [showPendingPaymentWarning, setShowPendingPaymentWarning] = useState(false);
  const [openCheckoutStepper, setOpenCheckoutStepper] = useState(false);
  const [selectedShop, setSelectedShop] = useState();
  const [checkoutStepperActiveStep, setCheckoutStepperActiveStep] = useState(0);
  const [showCheckout, setShowCheckout] = useState(false);
  const [shopProducts, setShopProducts] = useState();
  const [shopCategories, setShopCategories] = useState();
  const [shopProductsView, setShopProductsView] = useState();
  const [shippingMethods, setShippingMethods] = useState([]);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [inventoryUpdateTime, setInventoryUpdateTime] = useState();
  const [productDetailsMap, setProductDetailsMap] = useState({});
  const [productSearchResults, setProductSearchResults] = useState([]);
  const [productSearchQuery, setProductSearchQuery] = useState('');
  const [stores, setStores] = useState([]);
  const [isOutlet, setIsOutlet] = useState(false);
  const [message, setMessage] = useState(null);
  const [oktaClaims, setOktaClaims] = useState();
  const [openChargeAfterModal, setOpenChargeAfterModal] = useState(false);
  const oktaAuth = useOktaAuth();

  const sortedStores = stores.sort((a, b) => {
    const nameA = a?.name['en-US'].toUpperCase();
    const nameB = b?.name['en-US'].toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  const populateProducts = async () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      const currentDate = new Date();
      try {
        const categories = await ProductServices.getCategories();
        setShopCategories(categories);

        const products = await ProductServices.getAllProducts(categories);
        setShopProducts(products);

        setInventoryUpdateTime(currentDate);
      } catch (err) {
        throw new Error(err);
      }
    }
  };

  const populateOktaClaims = () => {
    const oktaData = JSON.parse(sessionStorage.getItem('okta-token-storage'));
    if (oktaData) {
      setOktaClaims(oktaData?.accessToken?.claims);
    }
    return null;
  };

  const populateCart = async () => {
    const currentCartId = getMostRecentCart();
    if (currentCartId) {
      const result = await CartService.getCart(currentCartId);
      if (result?.data) {
        setCart(result.data);
      }
    }
  };

  useEffect(() => {
    populateProducts();
    populateCart();
    populateOktaClaims();
  }, [oktaAuth?.oktaAuth?.authStateManager?._authState]);

  return {
    showShippingSnackbar,
    setShowShippingSnackbar,
    showPendingPaymentWarning,
    setShowPendingPaymentWarning,
    openCheckoutStepper,
    setOpenCheckoutStepper,
    checkoutStepperActiveStep,
    setCheckoutStepperActiveStep,
    showCheckout,
    setShowCheckout,
    cart,
    setCart,
    oldCart,
    setOldCart,
    selectedShop,
    setSelectedShop,
    shopCategories,
    setShopCategories,
    shopProducts,
    populateProducts,
    inventoryUpdateTime,
    setShopProducts,
    shopProductsView,
    setShopProductsView,
    shippingMethods,
    setShippingMethods,
    addToCartLoading,
    setAddToCartLoading,
    oktaClaims,
    productDetailsMap,
    setProductDetailsMap,
    sortedStores,
    setStores,
    isOutlet,
    setIsOutlet,
    openChargeAfterModal,
    setOpenChargeAfterModal,
    message,
    setMessage,
    productSearchResults,
    setProductSearchResults,
    productSearchQuery,
    setProductSearchQuery,
  };
};

const StoreContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const StoreContextProvider = ({ children }) => (
  <StoreContext.Provider value={useStore()}>{children}</StoreContext.Provider>
);

export const useCart = () => useContext(StoreContext).cart;
export const useOldCart = () => useContext(StoreContext).oldCart;
export const useSetOldCart = () => useContext(StoreContext).setOldCart;
export const useSetCart = () => useContext(StoreContext).setCart;
export const useSelectedShop = () => useContext(StoreContext).selectedShop;
export const useSetSelectedShop = () => useContext(StoreContext).setSelectedShop;
export const useShopCategories = () => useContext(StoreContext).shopCategories;
export const useSetShopCategories = () => useContext(StoreContext).setShopCategories;
export const useShopProducts = () => useContext(StoreContext).shopProducts;
export const usePopulateProducts = () => useContext(StoreContext).populateProducts;
export const useInventoryUpdateTime = () => useContext(StoreContext).inventoryUpdateTime;
export const useSetShopProducts = () => useContext(StoreContext).setShopProducts;
export const useShopProductsView = () => useContext(StoreContext).shopProductsView;
export const useSetShopProductsView = () => useContext(StoreContext).setShopProductsView;
export const useShippingMethods = () => useContext(StoreContext).shippingMethods;
export const useSetShippingMethods = () => useContext(StoreContext).setShippingMethods;
export const useAddToCartLoading = () => useContext(StoreContext).addToCartLoading;
export const useSetAddToCartLoading = () => useContext(StoreContext).setAddToCartLoading;
export const useOktaClaims = () => useContext(StoreContext).oktaClaims;
export const useShowPendingPaymentWarning = () =>
  useContext(StoreContext).showPendingPaymentWarning;
export const useOpenCheckoutStepper = () => useContext(StoreContext).openCheckoutStepper;
export const useSetOpenCheckoutStepper = () => useContext(StoreContext).setOpenCheckoutStepper;
export const useCheckoutStepperActiveStep = () =>
  useContext(StoreContext).checkoutStepperActiveStep;
export const useSetCheckoutStepperActiveStep = () =>
  useContext(StoreContext).setCheckoutStepperActiveStep;
export const useShowCheckout = () => useContext(StoreContext).showCheckout;
export const useSetShowCheckout = () => useContext(StoreContext).setShowCheckout;
export const useSetShowPendingPaymentWarning = () =>
  useContext(StoreContext).setShowPendingPaymentWarning;
export const useProductDetailsMap = () => useContext(StoreContext).productDetailsMap;
export const useSetProductDetailsMap = () => useContext(StoreContext).setProductDetailsMap;
export const useShowShippingSnackbar = () => useContext(StoreContext).showShippingSnackbar;
export const useSetShowShippingSnackbar = () => useContext(StoreContext).setShowShippingSnackbar;
export const useSortedStores = () => useContext(StoreContext).sortedStores;
export const useSetStores = () => useContext(StoreContext).setStores;
export const useIsOutlet = () => useContext(StoreContext).isOutlet;
export const useSetIsOutlet = () => useContext(StoreContext).setIsOutlet;
export const useOpenChargeAfterModal = () => useContext(StoreContext).openChargeAfterModal;
export const useSetOpenChargeAfterModal = () => useContext(StoreContext).setOpenChargeAfterModal;
export const useMessage = () => useContext(StoreContext).message;
export const useSetMessage = () => useContext(StoreContext).setMessage;
export const useProductSearchResults = () => useContext(StoreContext).productSearchResults;
export const useSetProductSearchResults = () => useContext(StoreContext).setProductSearchResults;
export const useProductSearchQuery = () => useContext(StoreContext).productSearchQuery;
export const useSetProductSearchQuery = () => useContext(StoreContext).setProductSearchQuery;
